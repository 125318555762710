// import "rsuite/dist/rsuite.min.css";
import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    if (name.length === 0) {
      alert("Name has left Blank!");
    } else if (email.length === 0) {
      alert("Email has left Blank!");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      alert("Email is invalid!");
    } else if (phone.length === 0) {
      alert("Mobile number has been left blank!");
    } else if (!/^\d{10}$/.test(phone)) {
      alert("Mobile number is invalid!");
    } else {
      const url = "https://quarkfinancials.com/api/api-insert-contact.php";

      let fData = new FormData();
      fData.append("name", name);
      fData.append("email", email);
      fData.append("phone", phone);
      fData.append("subject", subject);
      fData.append("message", message);
      setIsSubmitting(true);
      console.log(fData);
      axios
        .post(url, fData)
        .then((response) => {
          if (response.data.result) {
            Swal.fire({
              title: "Success",
              text: response.data.message,
              icon: "success",
              // timer: 1500
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.message,
            });
            alert(response.data.message);
          }
        })
        .catch((error) => {
          alert("An error occurred while inserting contact. " + error.message);
        })
        .finally(() => {
          setIsSubmitting(false);
          setName("");
          setPhone("");
          setEmail("");
          setSubject("");
          setMessage("");
        });
    }
  };

  return (
    <div>
      {/* page heading title section start  */}
      <div className="about-banner-area p-relative">
        <div className="about-shape-1 z-index-3">
          <img src="img/breadcrumb-shape-1.png" alt="" />
        </div>
        <div className="about-shape-2 z-index-3">
          <img src="img/breadcrumb-shape-2.png" alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pageTitle"> Contact Us</div>
            </div>
          </div>
        </div>
      </div>

      {/* page heading title section end  */}

      {/* aboutus section start  */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 mb-2">
              <h2 className="sectionTitle">
                <span>Get In Touch </span> With Us
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="pel-address">
                <ul>
                  <li>
                    <div className="icon">
                      <i className="flaticon-call"></i>
                    </div>
                    <p>Phone:</p>
                    <a href="tel:018823456789">+91 9826092090</a>
                  </li>
                </ul>
              </div>

              <div className="pel-address">
                <ul>
                  <li>
                    <div className="icon">
                      <i className="flaticon-email"></i>
                    </div>
                    <p>Email:</p>
                    <a href="mailto: info@quarkfinancials.com">
                      {" "}
                      info@quarkfinancials.com
                    </a>
                  </li>
                </ul>
              </div>

              <div className="pel-address">
                <ul>
                  <li>
                    <div className="icon">
                      <i className="flaticon-address"></i>
                    </div>
                    <p>Location:</p>
                    <span>SH-69, HIG, Vijay Nagar Indore, India 452010</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <form className="contact-form">
                <div className="row">
                  <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        autoComplete="off"
                        name="name"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Name"
                      />

                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        placeholder="Email"
                      />

                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                    <div className="form-group">
                      <input
                        type="number"
                        className="form-control"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        id="phone"
                        placeholder="Phone"
                      />

                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6 col-md-6 col-xl-6">
                    <div className="form-group">
                      <input
                        type="text"
                        required=""
                        className="form-control"
                        placeholder="Subject"
                        name="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />

                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        className="form-control textarea"
                        cols="30"
                        rows="8"
                        required=""
                        placeholder="Message"
                        name="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>

                      <div className="help-block with-errors"></div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <button
                      className="button type3 themeBtn"
                      type="button"
                      onClick={handleSubmit}
                    >
                      {isSubmitting && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}{" "}
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* aboutus section end   */}

      {/* map section start   */}
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d29434.714702509697!2d75.85851395976063!3d22.752784738078248!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396302aa3765b19b%3A0xc7e0c53a7a214df!2sHig%2C%20Vijay%20Nagar%2C%20Indore%2C%20Madhya%20Pradesh%20452010!5e0!3m2!1sen!2sin!4v1682575182760!5m2!1sen!2sin"
        width="100%"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>

      {/* map section end   */}
    </div>
  );
};
