import React from "react";

export const About = () => {
  return (
    <div>
        
                {/* page heading title section start  */}
<div className="about-banner-area p-relative">
    <div className="about-shape-1 z-index-3">
        <img src="img/breadcrumb-shape-1.png" alt=""/>
    </div>
    <div className="about-shape-2 z-index-3">
        <img src="img/breadcrumb-shape-2.png" alt=""/>
    </div>
    <div className="container">
        <div className="row">
            <div className="col-md-12">
               <div className="pageTitle"> About Us</div>
            </div>
        </div>
    </div>

</div>

 {/* page heading title section end   */}

 {/* aboutus section start  */}
<section>
    <div className="container">
        <div className="row">
            <div className="col-xl-6 col-lg-6">
                <h2 className="sectionTitle">
                    <span>About</span> Us
                </h2>
                <p>
                   <b> We offer a wide array of services varying in scope in a time bound budget friendly manner.</b>
                </p>
            
                <div className="client_card pricing_block blockwhite">
<div className="user_img">
<div className="icon">
    <img src="img/OurVision.png" alt="image"/>
    <div className="dot_block">
        <span className="dot_anim"></span>
        <span className="dot_anim"></span>
        <span className="dot_anim"></span>
    </div>
</div>
</div>
<div className="inf_content">
    <div className="subHeading">Our Vision</div>
    We see ourselves as perferred partners for small and medium sized organizations looking to harness latest digital
    technolgies
</div>
                </div>
                <div className="client_card pricing_block blockwhite">
                    <div className="user_img">
                        <div className="icon">
                            <img src="img/OurMission.png" alt="image"/>
                            <div className="dot_block">
                                <span className="dot_anim"></span>
                                <span className="dot_anim"></span>
                                <span className="dot_anim"></span>
                            </div>
                        </div>
                    </div>
                    <div className="inf_content">
                        <div className="subHeading">Our Mission</div>
                    To help small and medium sized organizations to leverage latest technologies in a cost effective manner
                    </div>
                </div>
            
            </div>
<div className="col-xl-6 col-lg-6">
    <img src="img/aboutus.jpg" className="img-fluid borderRadius"/>
</div>



        </div>
    </div>
    <img src="img/shap_color_7.png" alt="" className="shap_color"/>
</section>

 {/* aboutus section end  */}

<div className="about-banner-area p-relative">
    <div className="about-shape-1 z-index-3">
        <img src="img/breadcrumb-shape-1.png" alt=""/>
    </div>
    <div className="about-shape-2 z-index-3">
        <img src="img/breadcrumb-shape-2.png" alt=""/>
    </div>
<div className="container">
    <div className="row">
        <div className="col-md-12 text-center whiteText f15">
            <div className="mb-4 f30 mt-5">Need Financial Advisor For Your Business? Contact Us Today</div>
       <p className="fwNormle mb-5">
            Look for someone with who you feel comfortable in discussing your finances, a person <br/>
        who fits your needs for a fee-based or commission-based advisor. Make sure your <br/>
        planner or advisor has experience working with clients similar to you and <br/>
        your financial needs and who will communicate in a timely and organized matter.
        </p>

        <a href="#" className="button type3">contact Us</a>
        
        </div>
    </div>
</div>

                        </div>
    </div>
  );
};
